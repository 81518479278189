<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.userInfo.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>用户</el-breadcrumb-item>
                    <el-breadcrumb-item>添加服务配置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="searchright">
                        <el-button size="small" type="primary" @click="openadd">+添加服务配置</el-button>
                    </div>
                    <div class="top"></div>
                    <el-table :data="list" :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="channels" label="渠道"></el-table-column>
                        <el-table-column prop="type" label="业务类型">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.type == 1" type="info">代付</el-tag>
                                <el-tag v-if="scope.row.type == 2" type="info">代收</el-tag>
                                <el-tag v-if="scope.row.type == 3" type="info">提现</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cost" label="服务费"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" :width="160">
                            <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="120">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up :title="status == 1 ? '添加服务配置' : '编辑服务配置'" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 900px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="渠道" prop="channel">
                        <el-select v-model="form.channel" placeholder="请选择">
                            <el-option v-for="item in channellist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="业务类型" prop="type">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option label="代付" value="1"></el-option>
                            <el-option label="代收" value="2"></el-option>
                            <el-option label="提现" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="服务费" prop="cost">
                        <el-input-number v-model="form.cost" :min="0" :max="1"/>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    id: null,
                    cost: null,
                    type: null,
                },
                rules:{
                    channel:[{ required: true, trigger: 'blur' },],
                    cost:[{ required: true, trigger: 'blur' },],
                    type:[{ required: true, trigger: 'blur' },],
                },
                defaultProps: {
                    label: "name",
                    isLeaf: 'isLeaf'
                },
                    searchform: {
                    blurry: "",
                },
                loading: false,
                showadd: false,
                list: [],
                value1:[],
                channellist:[],
            }
        },
        created() {
            this.userInfo=JSON.parse(localStorage.getItem("userinfo"))
            this.searchform.userId = this.$route.query.accountId
            this.getList()
            this.getchannel()
        },
        methods: {
            getchannel() {
                _api.get(apiurl.channelsList, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.channellist = newarr
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    userId: this.$route.query.accountId,
                    cost: null,
                    type: null,
                    channel: null,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.userId = row.id
                this.form.cost = row.cost
                this.form.type = row.type
                this.form.channel = row.channel
                this.showadd = true
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            handleNodeClick(mydata, node, obj) {
                this.loading = true
                this.getList()
            },
            getconfirm() {
                if(this.form.cost==null || this.form.cost==""){
                    this.$message.error("不能为空")
                    return
                }
                if(this.form.type <= 0){
                    this.$message.error(this.$t('startLgend'))
                    return
                }
                if (this.status == 1) { //如果是添加
                    _api.post(apiurl.coverCharge, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            cost: null,
                            type: null,
                            channel: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.coverCharge, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            cost: null,
                            type: null,
                            channel: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
                  
            },
            getList() {
                this.loading = true
                _api.get(apiurl.coverCharge, {
                    userId:this.$route.query.accountId,
                }).then(res => {
                    this.loading = false
                    this.blurry=null
                    this.list = res.data
                })
            },
            onSubmit() {
                this.getList()
            },
            
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .top {
        margin-top: 15px;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>